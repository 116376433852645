// Firebase config here
export const firebaseConfig = {
  apiKey: "AIzaSyDm7rsAGThmAIJdzdVTwTSRxXYaxTZVpSI",
  authDomain: "nha-hat-online-dev.firebaseapp.com",
  projectId: "nha-hat-online-dev",
  storageBucket: "nha-hat-online-dev.appspot.com",
  messagingSenderId: "639635485441",
  appId: "1:639635485441:web:c97b921a1945e8ab28a100",
  measurementId: "G-TDN9JLSS78",
};
